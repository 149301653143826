import mobx from "../../store/mobx";
import {observer} from 'mobx-react-lite'
import './dollar.scss'

const Dollar = observer(() => {
    const confettiNum = 400;
    const coinNum = 30;
    const dollarNum = 50;

    const generateElements = (prefix, num) => {
      return Array.from({ length: num + 1 }, (_, index) => (
        <div key={index} className={`${prefix}-${index}`}>
        </div>
      ));
    };

    if(mobx.dollarVisibe){
              return (
    <div>
      {generateElements('dollar', dollarNum)}
      {generateElements('coin', coinNum)}
      {generateElements('confetti', confettiNum)}
    </div>
  );
    }else{
        return null
    }

})
 
export default Dollar;