import DisclamerModal from "../../components/DisclamerModal/DisclamerModal";
import Hero from "../../components/Hero/Hero";


const Web = ({height,display}) => {
    return ( 
<div style={{height:height,display:display}}>
    <DisclamerModal/>
    <Hero/>
</div>
     );
}
 
export {Web};