import css from './ModalByToken.module.css'
import close from '../../img/Close1.png'
import mobx from '../../store/mobx'
import {observer} from 'mobx-react-lite'
import {motion} from 'framer-motion'
import cookie1 from '../../img/cookie1.png'
import iconModalCard from '../../img/iconModalCard.png'

const ModalByToken = observer(() => {
    if(mobx.modalByToken){
        return ( 
            <>
                <motion.section onClick={()=>mobx.setModalByToken(false)}  initial={{opacity:0}} whileInView={{opacity:1}}  className={css.container}>     
                <div className={css.modalWind} onClick={e => e.stopPropagation()} >
                    <img src={cookie1} className={css.cookie}/>
                    <h2 className={css.header}>BUY $COOKIES</h2>
                    <img  src={close} onClick={()=>mobx.setModalByToken(false)} className={css.close}/>
                    <div className={css.cardContainer}>
                        {
                            mobx.binanceArrow.map((binance => (
                                <a href={binance.link} className={css.card} key={binance.name} target='blank'>
                                <img className={css.icon} src={`assets/img/${binance.icon}`} />
                                    <span className={css.cardHeader}>{binance.name}</span>
                                </a>
                            )))
                        }
                    </div>
                </div>
                
                </motion.section>
            
            </>
                 );
    }
   
})
 
export default ModalByToken;