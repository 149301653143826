import { useEffect } from 'react'
import css from './State3.module.css'
import {motion} from 'framer-motion'
import mobx from '../../../store/mobx'

const State3 = () =>{
    
    useEffect(()=>{
        mobx.setDollarVisible(true)

        return ()=>{
            mobx.setDollarVisible(false)
        }

    },[])
    return(
<>

<motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:5,duration:1}} className={css.block2Container}
onClick={()=>window.location.href='https://t.me/CookiesBand_bot/Cookies'}
>
                        <span data-text="To start earning Cookies tokens, simply click the button below and begin your journey to token rewards!" className={`${css.block2Header} `}>To start earning Cookies tokens, simply click the button below and begin your journey to token rewards!</span>
    <a className={css.button} href='https://t.me/CookiesBand_bot/Cookies' target='blank'>Get $COOKIES</a>
   
</motion.div>
</>
    )
}

export default State3