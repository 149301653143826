
import css from './State5.module.css'
import {motion} from 'framer-motion'

const State5 = () =>{
    return(
<>
<motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:5,duration:1}} className={css.container}>
    <div className={css.block1Container}>
        <div className={css.glitchWrapper}>
                        <span data-text="Full statistic" className={`${css.block2Header} ${css.glitch}`}>Full statistic</span>
                    </div>
        <div className={css.column}>
            <span className={css.columnHeader}>Total Cookies tokens:</span>
            <span className={css.columnCount}>35.000.000.000</span>
        </div>
        <div className={css.column}>
            <span className={css.columnHeader}>Total people in world:</span>
            <span className={css.columnCount}>7.950.000.000</span>
        </div>
        <div className={css.column}>
            <span className={css.columnHeader}>Total cookies for one:</span>
            <span className={css.columnCount}>4.4 cookies</span>
        </div>
        {/* <div className={css.column}>
            <span className={css.columnHeader}>total tokens:</span>
            <span className={css.columnCount}>20 000 000</span>
        </div> */}
    </div>
    <div className={css.block2}>
        <div className={css.block2Container}>
            <div className={css.glitchWrapper}>
                        <span data-text="Last great news" className={`${css.block2Header} ${css.glitch}`}>Last great news</span>
                    </div>
            <p className={css.block2descr}>
            We've launched our first game on Telegram and we're thrilled to welcome players. 
            Our game is fantastic, offering an engaging experience, and we're actively refining it every day to 
            make it even better. Come join us for some fun and excitement!
            </p>
            <span className={css.block2Domine}>t.me/cookies_band</span>
        </div>
        <p className={css.block2Descr2}>
        The Cookies token serves as a utility token on the SOLANA blockchain, facilitating seamless transactions and interactions 
        within its own ecosystem. SOLANA blockchain plays a crucial role in enabling efficient and secure operations, 
        empowering users with functionalities that enhance their experience and engagement across the platform.
        </p>
        <div className={css.dimainBlock}>
            <span className={css.dimainBlockDomain}>cookies.band</span>
            <span className={css.dimainBlockDomain}>cookies.band</span>
            <span className={css.dimainBlockDomain}>cookies.band</span>
            {/* <span className={css.dimainBlockDomain}>domain.com</span> */}
        </div>
    </div>
</motion.div>

</>
    )
}

export default State5