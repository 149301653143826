import mobx from '../store/mobx'
import {observer} from 'mobx-react-lite'

import React, { useLayoutEffect, useRef, useState } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'

const CookiePieces1 = observer((props) => {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/cookiePieces1.gltf')
  const { actions, names } = useAnimations(animations, group);
  const [scale,setScale] = useState(1)
  
  useLayoutEffect(() => {
    const width = window.screen.width
    if (width<768){
      setScale(0.4)
    }else if(width<1024){
      setScale(0.4)
    }
    actions.animation_0.timeScale = 5
    group.current.visible = false
    if (mobx.group3){
      group.current.visible = true
          names.forEach((animation) => {
      actions?.[animation]?.play();
      // Отключаем зацикленность анимации
      actions.animation_0.repetitions = true
    });
    }
  }, [actions, names,mobx.group3]);
  return (
    <group ref={group} {...props} dispose={null} scale={0.4}>
      <group>
        <group name="Cookie" position={[-14.632, 0, -12.415]}>
          <group name="Pieces_013" position={[172.886, -1.353, -27.48]} rotation={[Math.PI, 0.526, Math.PI]}>
            <group name="Pieces_18_1" position={[-3.798, 0, -49.095]} scale={0.948}>
              <group name="Pieces_18_1Null_(Copy)">
                <mesh name="Pieces_18_1Sphere_45_0" geometry={nodes.Pieces_18_1Sphere_45_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.435, 0, -30.821]} rotation={[1.166, 0.737, -1.417]} scale={[0.028, -0.028, 0.028]} />
                <mesh name="Pieces_18_1Sphere_45_1" geometry={nodes.Pieces_18_1Sphere_45_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[25.906, 0, -17.835]} rotation={[1.097, 0.133, 0.396]} scale={[0.161, -0.161, 0.161]} />
                <mesh name="Pieces_18_1Sphere_45_2" geometry={nodes.Pieces_18_1Sphere_45_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[51.279, 0, 7.979]} rotation={[-0.775, -0.209, 3.085]} scale={2.109} />
                <mesh name="Pieces_18_1Sphere_45_3" geometry={nodes.Pieces_18_1Sphere_45_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[77.913, 0, -31.824]} rotation={[2.261, 0.143, 0.323]} scale={1.811} />
                <mesh name="Pieces_18_1Sphere_45_4" geometry={nodes.Pieces_18_1Sphere_45_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[110.637, 0, 24.454]} rotation={[-2.144, 0.802, 0.703]} scale={[0.227, -0.227, 0.227]} />
              </group>
            </group>
            <group name="Pieces_18_2" position={[-6.749, 0, -82.18]} scale={0.746}>
              <group name="Pieces_18_2Null_(Copy)">
                <mesh name="Pieces_18_2Sphere_45_0" geometry={nodes.Pieces_18_2Sphere_45_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.435, 0, -30.821]} rotation={[1.166, 0.737, -1.417]} scale={[0.028, -0.028, 0.028]} />
                <mesh name="Pieces_18_2Sphere_45_1" geometry={nodes.Pieces_18_2Sphere_45_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[25.906, 0, -17.835]} rotation={[1.097, 0.133, 0.396]} scale={[0.161, -0.161, 0.161]} />
                <mesh name="Pieces_18_2Sphere_45_2" geometry={nodes.Pieces_18_2Sphere_45_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[51.279, 0, 7.979]} rotation={[-0.775, -0.209, 3.085]} scale={2.109} />
                <mesh name="Pieces_18_2Sphere_45_3" geometry={nodes.Pieces_18_2Sphere_45_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[77.913, 0, -31.824]} rotation={[2.261, 0.143, 0.323]} scale={1.811} />
                <mesh name="Pieces_18_2Sphere_45_4" geometry={nodes.Pieces_18_2Sphere_45_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[110.637, 0, 24.454]} rotation={[-2.144, 0.802, 0.703]} scale={[0.227, -0.227, 0.227]} />
              </group>
            </group>
            <group name="Pieces_18_0" position={[1.44, 0, 5.468]} scale={1.429}>
              <group name="Pieces_18_0Null_(Copy)">
                <mesh name="Pieces_18_0Sphere_45_1" geometry={nodes.Pieces_18_0Sphere_45_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[25.906, 0, -17.835]} rotation={[1.097, 0.133, 0.396]} scale={[0.161, -0.161, 0.161]} />
                <mesh name="Pieces_18_0Sphere_45_3" geometry={nodes.Pieces_18_0Sphere_45_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[77.913, 0, -31.824]} rotation={[2.261, 0.143, 0.323]} scale={1.811} />
              </group>
            </group>
          </group>
          <group name="Pieces_012" position={[53.683, -1.353, -59.995]} rotation={[Math.PI, 1.224, -Math.PI]}>
            <group name="Pieces_16_1" position={[-4.86, 0, -59.936]} scale={1.037}>
              <group name="Pieces_16_1Null_(Copy)">
                <mesh name="Pieces_16_1Sphere_42_0" geometry={nodes.Pieces_16_1Sphere_42_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.441, 0, -1.593]} rotation={[-0.03, -0.91, 2.32]} scale={1.944} />
                <mesh name="Pieces_16_1Sphere_42_1" geometry={nodes.Pieces_16_1Sphere_42_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[31.213, 0, -18.335]} rotation={[-0.477, 0.636, -0.806]} scale={2.067} />
                <mesh name="Pieces_16_1Sphere_42_2" geometry={nodes.Pieces_16_1Sphere_42_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[58.92, 0, 19.022]} rotation={[3.069, 0.351, -1.911]} scale={[0.2, -0.2, 0.2]} />
                <mesh name="Pieces_16_1Sphere_42_3" geometry={nodes.Pieces_16_1Sphere_42_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[71.38, 0, 24.421]} rotation={[2.378, 0.02, -1.215]} scale={1.38} />
                <mesh name="Pieces_16_1Sphere_42_4" geometry={nodes.Pieces_16_1Sphere_42_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[106.401, 0, -8.641]} rotation={[0.673, -0.866, -3.061]} scale={1.466} />
              </group>
            </group>
            <group name="Pieces_16_2" position={[0.123, 0, -119.671]} scale={0.662}>
              <group name="Pieces_16_2Null_(Copy)">
                <mesh name="Pieces_16_2Sphere_42_0" geometry={nodes.Pieces_16_2Sphere_42_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.441, 0, -1.593]} rotation={[-0.03, -0.91, 2.32]} scale={1.944} />
                <mesh name="Pieces_16_2Sphere_42_1" geometry={nodes.Pieces_16_2Sphere_42_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[31.213, 0, -18.335]} rotation={[-0.477, 0.636, -0.806]} scale={2.067} />
                <mesh name="Pieces_16_2Sphere_42_2" geometry={nodes.Pieces_16_2Sphere_42_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[58.92, 0, 19.022]} rotation={[3.069, 0.351, -1.911]} scale={[0.2, -0.2, 0.2]} />
                <mesh name="Pieces_16_2Sphere_42_3" geometry={nodes.Pieces_16_2Sphere_42_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[71.38, 0, 24.421]} rotation={[2.378, 0.02, -1.215]} scale={1.38} />
                <mesh name="Pieces_16_2Sphere_42_4" geometry={nodes.Pieces_16_2Sphere_42_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[106.401, 0, -8.641]} rotation={[0.673, -0.866, -3.061]} scale={1.466} />
              </group>
            </group>
            <group name="Pieces_16_0_" position={[1.441, 0, 7.875]} scale={0.93}>
              <group name="Pieces_16_0Null_(Copy)">
                <mesh name="Pieces_16_0Sphere_42_1" geometry={nodes.Pieces_16_0Sphere_42_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[31.213, 0, -18.335]} rotation={[-0.477, 0.636, -0.806]} scale={2.067} />
              </group>
            </group>
          </group>
          <group name="Pieces_011" position={[-69.679, -40, -84.226]} rotation={[0, -0.698, 0]}>
            <group name="Pieces01_1_0Pieces01_1_0" position={[1.441, 0, 7.875]} scale={0.93}>
              <mesh name="Pieces01_1_0Sphere_37" geometry={nodes.Pieces01_1_0Sphere_37.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[33.798, 0, -31.802]} rotation={[-2.051, 0.311, 2.91]} scale={1.206} />
              <mesh name="Pieces01_1_0Sphere_36" geometry={nodes.Pieces01_1_0Sphere_36.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[66.749, 0, -24.723]} rotation={[3.098, -0.224, 1.846]} scale={2.016} />
              <mesh name="Pieces01_1_0Sphere_35" geometry={nodes.Pieces01_1_0Sphere_35.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[90.722, 0, 32.089]} rotation={[-0.546, 1.106, 1.428]} scale={2.828} />
              <mesh name="Pieces01_1_0Sphere_34" geometry={nodes.Pieces01_1_0Sphere_34.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[123.531, 0, 0.184]} rotation={[-2.429, 0.158, 1.924]} scale={2.081} />
              <mesh name="Pieces01_1_0Sphere_33" geometry={nodes.Pieces01_1_0Sphere_33.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[145, 0, 8.816]} rotation={[1.219, 1.386, -0.033]} scale={[0.462, -0.462, 0.462]} />
              <mesh name="Pieces01_1_0Sphere_95" geometry={nodes.Pieces01_1_0Sphere_95.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[185.84, 0, -5.15]} rotation={[-1.654, 0.697, 0.445]} scale={0.532} />
              <mesh name="Pieces01_1_0Sphere_94" geometry={nodes.Pieces01_1_0Sphere_94.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[208.668, 0, -15.641]} rotation={[-1.93, 0.866, 1.837]} scale={[0.512, -0.512, 0.512]} />
            </group>
            <group name="Pieces01_1_1Pieces01_1_1" position={[-4.86, 0, -59.936]} scale={1.037}>
              <mesh name="Pieces01_1_1Sphere_35" geometry={nodes.Pieces01_1_1Sphere_35.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[90.722, 0, 32.089]} rotation={[-0.546, 1.106, 1.428]} scale={2.828} />
              <mesh name="Pieces01_1_1Sphere_95" geometry={nodes.Pieces01_1_1Sphere_95.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[185.84, 0, -5.15]} rotation={[-1.654, 0.697, 0.445]} scale={0.532} />
              <mesh name="Pieces01_1_1Sphere_94" geometry={nodes.Pieces01_1_1Sphere_94.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[208.668, 0, -15.641]} rotation={[-1.93, 0.866, 1.837]} scale={[0.512, -0.512, 0.512]} />
            </group>
          </group>
        </group>
        <PerspectiveCamera name="Cam" makeDefault={false} far={10000000000} near={0.01} fov={4.718} position={[0, 8592.837, 0]} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
})

export default CookiePieces1
useGLTF.preload('/models/cookiePieces1.gltf')
