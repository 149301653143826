import { Outlet } from "react-router";
import Header from "../../components/Header/Header";
import Modal from "../../components/Modal/Modal";
import ModalByToken from "../../components/ModalByToken/ModalByToken";
import ModalConnectWallet from "../../components/ModalConnectWallet/ModalConnectWallet";


const Layout = () => {
    return ( 
<>
    <Modal/>
    <ModalByToken/>

    <ModalConnectWallet/>
    <Header/>
    <Outlet/>
</>
     );
}
 
export {Layout};