import css from './DisclamerModal.module.css'
import close from '../../img/Close1.png'
import mobx from '../../store/mobx'
import {observer} from 'mobx-react-lite'
import {motion} from 'framer-motion'
import cookie1 from '../../img/cookie1.png'
import { useEffect } from 'react'

const DisclamerModal = observer(() => {
    useEffect(()=>{
        var isConfirm = localStorage.getItem('isConfirm')
        if(!isConfirm){
            setTimeout(() => {
                mobx.setDisclamerModal(true);
            
                // Прокрутка страницы вверх
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            
                // Блокировка скролла
                document.body.style.overflow = 'hidden';
            }, 10000);
        }
        return ()=>{
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[10000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[1000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[2000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[3000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[4000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[5000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[6000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[7000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[8000])
            setTimeout(()=>{document.body.style.overflowY = 'auto';},[9000])

            document.body.style.overflowY = 'auto';
        }
    },[])

    if(mobx.disclamerModal){
        return ( 
            <>
                <motion.section onClick={()=>{
                    mobx.setDisclamerModal(false)
                    document.body.style.overflowY = 'auto';
                    }}   initial={{opacity:0}} whileInView={{opacity:1}}  className={css.container}>     
                <div className={css.modalWind} onClick={e => e.stopPropagation()}>
                    <img src={cookie1} className={css.cookie}/>
                    <h2 className={css.header}>DISCLAIMER</h2>
                    <img  src={close} onClick={()=>{
                        mobx.setDisclamerModal(false)
                        document.body.style.overflowY = 'auto';
                        }} className={css.close}/>
                    <p className={css.text}>I confirm that I am not a citizen of Afghanistan, Benin, Bhutan, China, Crimea region, Cuba, Iran, Iraq, Syria, 
                        USA, Vatican City, or a citizen of any country or jurisdiction where such distribution or use would be contrary 
                        to local law or regulation. Click Confirm .</p>
                        <span className={css.button} onClick={()=>{
                            mobx.setDisclamerModal(false)
                            localStorage.setItem('isConfirm',true)
                            document.body.style.overflowY = 'auto';
                        }}>Confirm </span>
                </div>
                
                </motion.section>
            
            </>
                 );
    }
   
})
 
export default DisclamerModal;