import css from './Footer.module.css'
import {motion} from 'framer-motion'

import topCookie from './img/topCookie.png'

const Footer = () => {
    return ( 
        
<>
    <motion.section viewport={{once:true,amount:.4}} initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1}} className={css.container} id='footer'>
        <div className={css.footerContainer}>
            <img src={topCookie} className={css.topCookie}/>
            <div className={css.textContainer1}>
                <span className={css.text1}>Proudly based worldwide</span>
                {/* <a href='/' className={css.text2}>0xac1b2186ofc1b218 6ofc1b2186ofc1b2186of</a> */}
                <a href="mailto:admin@toshithecat.com" className={css.text1}>boss@cookies.band</a>
            </div>
            <div className={css.textContainer2}>
                <span className={css.text1}>Contract</span>
                <a target='blank' href='https://www.dextools.io/app/ru/pairs' className={css.text2}>Dextools</a>
                <a target='blank' href='https://solscan.io' className={css.text2}>solscan </a>
            </div>
            <div className={css.textContainer3}>
                <span className={css.text1}>Social</span>
                <a target='blank' href='https://t.me/cookies_band' className={css.text2}>telegram</a>
                <a target='blank' href='https://twitter.com/cookies_bnd' className={css.text2}>twitter</a>
            </div>
            <div className={css.textContainer4}>
                <span className={css.text1}>More</span>
                <a  className={css.text2}>Toshi tools</a>
                <a  className={css.text1}>contact</a>
            </div>

        </div> {/* footerContainer */}
        <div className={css.footerMobileContainer}>
            <img src={topCookie} className={css.topCookie}/>
            <span className={css.text1}>Social</span>
            <div className={css.container1}>
                <div className={css.columnContainer}>
                <a target='blank' href='https://t.me/cookies_band' className={css.text2}>telegram</a>
                </div>
                <div className={css.columnContainer}>
                <a target='blank' href='https://twitter.com/cookies_bnd' className={css.text2}>twitter</a>
                </div> 
            </div>
            
            <div className={css.container1}>
                <div className={css.columnContainer}>
                <span className={css.text1}>Contract </span>
                <a target='blank' href='https://www.dextools.io/app/ru/pairs' className={css.text2}>Dextools</a>
                <a target='blank' href='https://solscan.io' className={css.text2}>solscan  </a>
                </div>
                <div className={css.columnContainer}>
                <span className={css.text1}>More</span>
                <a  className={css.text2}>Cookie tools</a>
                <a  className={css.text2}>contact</a>
                </div> 
            </div>
            <div className={css.columnContainer} style={{width:"90%"}}>
                <span className={css.text1}>Proudly based worldwide</span>
                    {/* <a href='/' className={css.text2} style={{width:"90%",fontSize:"3vw"}}>0xac1b2186ofc1b218 6ofc1b2186ofc1b2186of</a> */}
                    <a href="mailto:boss@cookies.band" className={css.text2}>boss@cookies.band</a>
            </div> 
        </div>
    </motion.section>
</>
    );
}
 
export default Footer;