import {observer} from 'mobx-react-lite'
import mobx from '../store/mobx'
import React, {useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import gsap from 'gsap'
import useSound from 'use-sound'
import {Howl, Howler} from 'howler';


const CookieFly = observer((props)=> {
  useEffect(()=>{

  },[])

  const group = useRef()
  const group1 = useRef()
  const group2 = useRef()
  const group3 = useRef()
  const { nodes, materials, animations } = useGLTF('/models/cookiecfly.gltf')
  const { actions, names } = useAnimations(animations, group);
  const [scale,setScale] = useState(1)
  var sound = new Howl({
    src: ['/mp3/crunch.mp3'],
    html5: true
  });
  var soundp = new Howl({
    src: ['/mp3/pabam.mp3'],
    html5: true
  });
  var sounds = new Howl({
    src: ['/mp3/swoosh.mp3'],
    html5: true
  });

  
  
  
  const [crunch] = useSound('/mp3/crunch.mp3')
  const [pabam] = useSound('/mp3/pabam.mp3')
  const [swoosh] = useSound('/mp3/swoosh.mp3')
  
  const click1 = ()=>{
    mobx.setCount(mobx.count+1)
    // group1.current.visible = false
    gsap.to(group1.current.position,{x:-120})
    gsap.to(group1.current,{visible:false})
    
    mobx.setGrop1()

    crunch()

  }
  const click2 = ()=>{
    mobx.setCount(mobx.count+1)

    gsap.to(group2.current.position,{x:80})
    gsap.to(group2.current.position,{z:130})
    gsap.to(group2.current,{visible:false})
    mobx.setGrop2()

    crunch()

  }
  const click3 = ()=>{
    mobx.setCount(mobx.count+1)

    gsap.to(group3.current.position,{x:80})
    gsap.to(group3.current.position,{z:-140})
    gsap.to(group3.current,{visible:false})
    mobx.setGrop3()

    crunch()

  }


  useLayoutEffect(() => {
    
    
    const width = window.screen.width

    setScale(0)

    // Отключаем зацикленность анимации
    actions.animation_0.repetitions = true
    setTimeout(()=>{sounds.play();},[800]) 
    setTimeout(()=>{
      soundp.play();
    },[4200])
    names.forEach((animation) => {
      setTimeout(()=>{
        actions?.[animation]?.play();
            if (width<768){
              setScale(0.6)
            }else if(width<1024){
              setScale(0.7)
            }else{setScale(1)}
      },[700])
      
      
    });
  }, [actions, names]);
  

// Рендер компонента только когда isReady равно true
  return  (
    <group ref={group} {...props} dispose={null} scale={scale}>
      <group>
        <group name="Cookie" position={[-14.632, 0, -12.415]}>
          <mesh onClick={click1} ref={group1} name="Piece_03" geometry={nodes.Piece_03.geometry} material={materials.Piece_01} position={[-67.964, 0.083, 11.323]} />
          <mesh onClick={click2} ref={group2} name="Piece_02" geometry={nodes.Piece_02.geometry} material={materials.Piece_01} position={[55.202, 1.219, 91.497]} />
          <mesh onClick={click3} ref={group3} name="Piece_01" geometry={nodes.Piece_01.geometry} material={materials.Piece_01} position={[53.599, -0.254, -69.447]} />
        </group>
      </group>
    </group>
  );
});

export default CookieFly
useGLTF.preload('/models/cookiecfly.gltf')
