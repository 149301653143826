import mobx from '../store/mobx'
import {observer} from 'mobx-react-lite'

import React, { useLayoutEffect, useRef, useState } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'

const CookiePieces2 = observer((props) => {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/cookiePieces2.gltf')
  const { actions, names } = useAnimations(animations, group);
  const [scale,setScale] = useState(1)
  
  useLayoutEffect(() => {
    const width = window.screen.width
    if (width<768){
      setScale(0.4)
    }else if(width<1024){
      setScale(0.4)
    }
    group.current.visible = false
    actions.animation_0.timeScale = 5
    if (mobx.group2){
      group.current.visible = true
          names.forEach((animation) => {
      actions?.[animation]?.play();
      // Отключаем зацикленность анимации
      actions.animation_0.repetitions = true
    });
    }


  }, [actions, names,mobx.group2]);
  return (
    <group ref={group} {...props} dispose={null} scale={0.4}>
      <group>
        <group name="Cookie" position={[-14.632, 0, -12.415]}>
          <group name="Pieces_023" position={[56.075, -1.353, 78.495]} rotation={[0, 0.347, 0]}>
            <group name="Pieces_22_1" position={[-3.798, 0, -50.095]} scale={0.948}>
              <group name="Pieces_22_1Null_(Copy)">
                <mesh name="Pieces_22_1Sphere_51_0" geometry={nodes.Pieces_22_1Sphere_51_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.431, 0, 19.642]} rotation={[2.503, -0.431, 0.036]} scale={1.988} />
                <mesh name="Pieces_22_1Sphere_51_1" geometry={nodes.Pieces_22_1Sphere_51_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[22.721, 0, -31.135]} rotation={[0.728, 1.176, 0.996]} scale={0.901} />
                <mesh name="Pieces_22_1Sphere_51_2" geometry={nodes.Pieces_22_1Sphere_51_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[57.895, 0, 28.553]} rotation={[0.612, -0.229, -1.507]} scale={1.095} />
                <mesh name="Pieces_22_1Sphere_51_3" geometry={nodes.Pieces_22_1Sphere_51_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[73.432, 0, 2.428]} rotation={[-1.534, 0.742, 1.767]} scale={2.07} />
                <mesh name="Pieces_22_1Sphere_51_4" geometry={nodes.Pieces_22_1Sphere_51_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[99.178, 0, -23.689]} rotation={[0.663, -1.055, -0.719]} scale={1.158} />
              </group>
            </group>
            <group name="Pieces_22_2" position={[-6.749, 0, -84.18]} scale={0.746}>
              <group name="Pieces_22_2Null_(Copy)">
                <mesh name="Pieces_22_2Sphere_51_0" geometry={nodes.Pieces_22_2Sphere_51_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.431, 0, 19.642]} rotation={[2.503, -0.431, 0.036]} scale={1.988} />
                <mesh name="Pieces_22_2Sphere_51_1" geometry={nodes.Pieces_22_2Sphere_51_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[22.721, 0, -31.135]} rotation={[0.728, 1.176, 0.996]} scale={0.901} />
                <mesh name="Pieces_22_2Sphere_51_2" geometry={nodes.Pieces_22_2Sphere_51_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[57.895, 0, 28.553]} rotation={[0.612, -0.229, -1.507]} scale={1.095} />
                <mesh name="Pieces_22_2Sphere_51_3" geometry={nodes.Pieces_22_2Sphere_51_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[73.432, 0, 2.428]} rotation={[-1.534, 0.742, 1.767]} scale={2.07} />
                <mesh name="Pieces_22_2Sphere_51_4" geometry={nodes.Pieces_22_2Sphere_51_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[99.178, 0, -23.689]} rotation={[0.663, -1.055, -0.719]} scale={1.158} />
              </group>
            </group>
            <group name="Pieces_22_0" position={[1.44, 0, 5.468]} scale={1.429}>
              <group name="Pieces_22_0Null_(Copy)">
                <mesh name="Pieces_22_0Sphere_51_1" geometry={nodes.Pieces_22_0Sphere_51_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[22.721, 0, -31.135]} rotation={[0.728, 1.176, 0.996]} scale={0.901} />
                <mesh name="Pieces_22_0Sphere_51_4" geometry={nodes.Pieces_22_0Sphere_51_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[99.178, 0, -23.689]} rotation={[0.663, -1.055, -0.719]} scale={1.158} />
              </group>
            </group>
          </group>
          <group name="Pieces_022" position={[11.53, -1.353, 176.021]} rotation={[0, 1.045, 0]}>
            <group name="Pieces_20_1" position={[-3.798, 0, -50.095]} scale={0.948}>
              <group name="Pieces_20_1Null_(Copy)">
                <mesh name="Pieces_20_1Sphere_48_0" geometry={nodes.Pieces_20_1Sphere_48_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.434, 0, -10.103]} rotation={[0.68, 0.98, -2.935]} scale={[0.693, -0.693, 0.693]} />
                <mesh name="Pieces_20_1Sphere_48_1" geometry={nodes.Pieces_20_1Sphere_48_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[25.552, 0, -4.202]} rotation={[-1.056, 0.755, -1.797]} scale={2.623} />
                <mesh name="Pieces_20_1Sphere_48_2" geometry={nodes.Pieces_20_1Sphere_48_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[58.237, 0, -19.957]} rotation={[2.059, 1.145, 1.334]} scale={0.663} />
                <mesh name="Pieces_20_1Sphere_48_3" geometry={nodes.Pieces_20_1Sphere_48_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[72.748, 0, -12.908]} rotation={[1.268, 0.454, 1.677]} scale={[0.827, -0.827, 0.827]} />
                <mesh name="Pieces_20_1Sphere_48_4" geometry={nodes.Pieces_20_1Sphere_48_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[110.919, 0, 26.661]} rotation={[-2.564, -0.904, 2.087]} scale={[0.073, -0.073, 0.073]} />
                <mesh name="Pieces_20_1Sphere_48_5" geometry={nodes.Pieces_20_1Sphere_48_5.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[134.961, 0, -6.399]} rotation={[-3.008, -1.08, -2.121]} scale={0.983} />
              </group>
            </group>
            <group name="Pieces_20_2" position={[-6.749, 0, -84.18]} scale={0.746}>
              <group name="Pieces_20_2Null_(Copy)">
                <mesh name="Pieces_20_2Sphere_48_0" geometry={nodes.Pieces_20_2Sphere_48_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.434, 0, -10.103]} rotation={[0.68, 0.98, -2.935]} scale={[0.693, -0.693, 0.693]} />
                <mesh name="Pieces_20_2Sphere_48_1" geometry={nodes.Pieces_20_2Sphere_48_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[25.552, 0, -4.202]} rotation={[-1.056, 0.755, -1.797]} scale={2.623} />
                <mesh name="Pieces_20_2Sphere_48_2" geometry={nodes.Pieces_20_2Sphere_48_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[58.237, 0, -19.957]} rotation={[2.059, 1.145, 1.334]} scale={0.663} />
                <mesh name="Pieces_20_2Sphere_48_3" geometry={nodes.Pieces_20_2Sphere_48_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[72.748, 0, -12.908]} rotation={[1.268, 0.454, 1.677]} scale={[0.827, -0.827, 0.827]} />
                <mesh name="Pieces_20_2Sphere_48_4" geometry={nodes.Pieces_20_2Sphere_48_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[110.919, 0, 26.661]} rotation={[-2.564, -0.904, 2.087]} scale={[0.073, -0.073, 0.073]} />
                <mesh name="Pieces_20_2Sphere_48_5" geometry={nodes.Pieces_20_2Sphere_48_5.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[134.961, 0, -6.399]} rotation={[-3.008, -1.08, -2.121]} scale={0.983} />
              </group>
            </group>
            <group name="Pieces_20_0" position={[1.44, 0, 5.468]} scale={1.429}>
              <group name="Pieces_20_0Null_(Copy)">
                <mesh name="Pieces_20_0Sphere_48_5" geometry={nodes.Pieces_20_0Sphere_48_5.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[134.961, 0, -6.399]} rotation={[-3.008, -1.08, -2.121]} scale={0.983} />
              </group>
            </group>
          </group>
          <group name="Pieces_021" position={[-29.774, -40, 1.661]} rotation={[Math.PI, -1.223, Math.PI]}>
            <group name="Pieces02_1_2" position={[0.123, 0, -81.004]} scale={0.662}>
              <mesh name="Pieces02_1_2Sphere_40_0" geometry={nodes.Pieces02_1_2Sphere_40_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[0.123, 0, -81.004]} scale={0.662} />
              <mesh name="Pieces02_1_2Sphere_40_1" geometry={nodes.Pieces02_1_2Sphere_40_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[28.213, 0, -18.335]} rotation={[-0.477, 0.636, -0.806]} scale={2.067} />
              <mesh name="Pieces02_1_2Sphere_40_2" geometry={nodes.Pieces02_1_2Sphere_40_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[52.92, 0, 19.022]} rotation={[3.069, 0.351, -1.911]} scale={[0.2, -0.2, 0.2]} />
              <mesh name="Pieces02_1_2Sphere_40_3" geometry={nodes.Pieces02_1_2Sphere_40_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[62.38, 0, 24.421]} rotation={[2.378, 0.02, -1.215]} scale={1.38} />
              <mesh name="Pieces02_1_2Sphere_40_4" geometry={nodes.Pieces02_1_2Sphere_40_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[94.401, 0, -8.641]} rotation={[0.673, -0.866, -3.061]} scale={1.466} />
              <mesh name="Pieces02_1_2Sphere_40_5" geometry={nodes.Pieces02_1_2Sphere_40_5.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[120.68, 0, -31.445]} rotation={[1.419, 1.041, 2.358]} scale={1.723} />
              <mesh name="Pieces02_1_2Sphere_40_6" geometry={nodes.Pieces02_1_2Sphere_40_6.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[132.891, 0, 5.633]} rotation={[2.289, -1.085, -0.053]} scale={0.621} />
              <mesh name="Pieces02_1_2Sphere_40_7" geometry={nodes.Pieces02_1_2Sphere_40_7.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[164.586, 0, -25.254]} rotation={[-2.828, 0.354, -3.07]} scale={1.894} />
            </group>
            <group name="Pieces02_1_0_" position={[1.441, 0, 7.875]} scale={0.93}>
              <mesh name="Pieces02_1_0Sphere_40_0" geometry={nodes.Pieces02_1_0Sphere_40_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-1.441, 0, -1.593]} rotation={[-0.03, -0.91, 2.32]} scale={1.944} />
              <mesh name="Pieces02_1_0Sphere_40_1" geometry={nodes.Pieces02_1_0Sphere_40_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[28.213, 0, -18.335]} rotation={[-0.477, 0.636, -0.806]} scale={2.067} />
              <mesh name="Pieces02_1_0Sphere_40_2" geometry={nodes.Pieces02_1_0Sphere_40_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[52.92, 0, 19.022]} rotation={[3.069, 0.351, -1.911]} scale={[0.2, -0.2, 0.2]} />
              <mesh name="Pieces02_1_0Sphere_40_3" geometry={nodes.Pieces02_1_0Sphere_40_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[62.38, 0, 24.421]} rotation={[2.378, 0.02, -1.215]} scale={1.38} />
              <mesh name="Pieces02_1_0Sphere_40_4" geometry={nodes.Pieces02_1_0Sphere_40_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[94.401, 0, -8.641]} rotation={[0.673, -0.866, -3.061]} scale={1.466} />
              <mesh name="Pieces02_1_0Sphere_40_5" geometry={nodes.Pieces02_1_0Sphere_40_5.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[120.68, 0, -31.445]} rotation={[1.419, 1.041, 2.358]} scale={1.723} />
              <mesh name="Pieces02_1_0Sphere_40_6" geometry={nodes.Pieces02_1_0Sphere_40_6.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[132.891, 0, 5.633]} rotation={[2.289, -1.085, -0.053]} scale={0.621} />
            </group>
            <group name="Pieces02_1_1" position={[-4.86, 0, -40.603]} scale={1.037}>
              <mesh name="Pieces02_1_1Sphere_40_0" geometry={nodes.Pieces02_1_1Sphere_40_0.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-4.86, 0, -40.603]} scale={1.037} />
              <mesh name="Pieces02_1_1Sphere_40_1" geometry={nodes.Pieces02_1_1Sphere_40_1.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[28.213, 0, -18.335]} rotation={[-0.477, 0.636, -0.806]} scale={2.067} />
              <mesh name="Pieces02_1_1Sphere_40_2" geometry={nodes.Pieces02_1_1Sphere_40_2.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[52.92, 0, 19.022]} rotation={[3.069, 0.351, -1.911]} scale={[0.2, -0.2, 0.2]} />
              <mesh name="Pieces02_1_1Sphere_40_3" geometry={nodes.Pieces02_1_1Sphere_40_3.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[62.38, 0, 24.421]} rotation={[2.378, 0.02, -1.215]} scale={1.38} />
              <mesh name="Pieces02_1_1Sphere_40_4" geometry={nodes.Pieces02_1_1Sphere_40_4.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[94.401, 0, -8.641]} rotation={[0.673, -0.866, -3.061]} scale={1.466} />
              <mesh name="Pieces02_1_1Sphere_40_5" geometry={nodes.Pieces02_1_1Sphere_40_5.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[120.68, 0, -31.445]} rotation={[1.419, 1.041, 2.358]} scale={1.723} />
              <mesh name="Pieces02_1_1Sphere_40_6" geometry={nodes.Pieces02_1_1Sphere_40_6.geometry} material={materials.Hazelnut_Cookie_01_Material} position={[132.891, 0, 5.633]} rotation={[2.289, -1.085, -0.053]} scale={0.621} />
            </group>
          </group>
        </group>
        <PerspectiveCamera name="Cam" makeDefault={false} far={10000000000} near={0.01} fov={4.718} position={[0, 8592.837, 0]} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
})

export default CookiePieces2
useGLTF.preload('/models/cookiePieces2.gltf')
