import css from './ModalConnectWallet.module.css'
import close from '../../img/Close1.png'
import mobx from '../../store/mobx'
import {observer} from 'mobx-react-lite'
import {motion} from 'framer-motion'
import cookie1 from '../../img/cookie1.png'

const ModalConnectWallet = observer(() => {
    if(mobx.modalConnectWallet){
        return ( 
            <>
                <motion.section onClick={()=>mobx.setModalConnectWallet(false)}   initial={{opacity:0}} whileInView={{opacity:1}}  className={css.container}>     
                <div className={css.modalWind} onClick={e => e.stopPropagation()}>
                    <img src={cookie1} className={css.cookie}/>
                    <h2 className={css.header}>Get a wallet</h2>
                    <img  src={close} onClick={()=>mobx.setModalConnectWallet(false)} className={css.close}/>
                    <div className={css.cardContainer}>
                        {mobx.connectArrow.map((connect)=> (
                            <a href={connect.link} className={css.card} key={connect.name} target='blank'>
                            <img className={css.icon} src={`assets/img/${connect.icon}`} />
                                <span className={css.cardHeader}>{connect.name}</span>
                            </a>
                        ))}
                    </div>
                </div>
                
                </motion.section>
            
            </>
                 );
    }
   
})
 
export default ModalConnectWallet;