import { Canvas } from "@react-three/fiber";
import React,{ Suspense, useEffect, useLayoutEffect, useRef, useState } from "react";
import { OrbitControls, PerspectiveCamera, Stage } from "@react-three/drei";
import img from './img/gif.gif'
import mobx from './store/mobx'
import {observer} from 'mobx-react-lite'
import {motion} from 'framer-motion'
import { lazy } from 'react'

import Loading from "./components/Loading/Loading";
// import CookieFly from "./components/Cookiecfly";

import CookieCrumbs from "./components/Cookiecrumbs";
import CookieFly from "./components/Cookiecfly"
import CookiePieces1 from "./components/CookiePieces1";
import CookiePieces2 from "./components/CookiePieces2";
// import CookiePieces3 from "./components/CookiePieces3";
import {Web} from "./pages/Web/Web";
import gsap from "gsap";
import { Route, Routes,useLocation } from "react-router";
import {Layout} from "./pages/Layout/Layout";
import Airdrop from "./pages/Airdrop/Airdrop";
import Info from "./pages/Info/Info";
import Header from "./components/Header/Header";
import Modal from "./components/Modal/Modal";
import ModalByToken from "./components/ModalByToken/ModalByToken";
import ModalConnectWallet from "./components/ModalConnectWallet/ModalConnectWallet";
import canvasClick from './img/canvasClick.png'
import TapToStart from "./components/TapToStart/TapToStart";
import star from './img/star/star.gif'
import Dollar from "./components/DOLLAR/Dollar";


// Добавление отложеннойзагрузки для минимального времени отображения прелоадера
const CookiePieces3 = lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./components/CookiePieces3")), 3500);
  });
});


const App =observer(()=> {
  const tl = gsap.timeline()
  const [webHeaght,setWebHeght] = useState('100vh') // Дсиплей для Web
  const [webDisplay,setWebDisplay] = useState(false)
  const [canvasDisplay, setCanvasDisplay]=useState('block')
  const canvasRef = useRef()
  const webRef = useRef()
  const [clickCanvas,setClickCanvas] = useState(true)
  const [canvasOpacity,setCanvasOpacity] = useState(true)


  useEffect(()=>{
    // Для того,чтобы Web загружался параллельно.но не вылез вперед loading
    if(mobx.count>2){
      // Расширяем окно WEB
      setWebHeght(true)
      // Плавно убираем непрозрачность канваса
      gsap.to(canvasRef.current,{opacity:0,duration:1})
      gsap.to(webRef.current,{opacity:1,duration:1})
      setClickCanvas(false)
      // Скрываем канвас
      setTimeout(()=>{
        setCanvasDisplay("none")
        
      },[1000])
      
    }else if(mobx.count>0){
      setWebDisplay(true)
      mobx.setStarVisible(false)
    }
  },[mobx.count])
  if(mobx.tapToStart){
    return (
      <>
  <Suspense  fallback={<Loading/>}  >
{mobx.starVisible&&
  <div className="starContainer"  style={{zIndex:'9999',position:"absolute"}}>
      <div className="starRelative">
        <motion.img initial={{opacity:1}} whileInView={{opacity:0}} transition={{delay:2}} src={star} className="star"/>
      </div>
    </div>
}

    
    <Canvas ref={canvasRef} shadows style={{cursor:'pointer', position:'fixed',zIndex:999,display:canvasDisplay,opacity:canvasOpacity}} >
      {/* Моели */}

      <CookieFly/> 
      <CookieCrumbs/>
      <CookiePieces1/>
      <CookiePieces2/>
      <CookiePieces3/>


      {/* Освещение */}
      <spotLight intensity={15111111} position={[-1111,1111,0]}/>
      <spotLight intensity={15111111} position={[1111,-1111,0]}/>
      <spotLight intensity={15111111} position={[1111,0,-1111]}/>
      <spotLight intensity={15111111} position={[-1111,0,1111]}/>
      {/* Положение камеры */}
      <PerspectiveCamera makeDefault position={[0,700,0]}/>
      {/* Для того,чтобы камера смотрела на модель сверху */}
      <OrbitControls enableZoom={false} enableDamping={false} enableRotate={false}/> 
      {/* <color args={[0.48, 0.33, 0.75]} attach="background"/> */}
    </Canvas>

    {clickCanvas&&
    <motion.div initial={{opacity:0}}  whileInView={{opacity:1}} transition={{duration:2,delay:3}} style={{zIndex:'9999',position:"fixed"}}>
      <div  className="canvasClickContainer">
        <img src={canvasClick} className="canvasClick" />
    
      </div>
      <div className="clickCanvas">tap on cookies</div>
    </motion.div>
    }
  </Suspense>
  
  
  {webDisplay&&
  <div className={`App`} style={{height:webHeaght,opacity:0} } ref={webRef} >
    <Dollar/>
    <Header/>
    <Modal/>
    <ModalByToken/>
    <ModalConnectWallet/>
    <Content />
  </div>
  }
      </>
  
    );
  }else{return(<TapToStart/>)}

})

export default App;




// Анимирование переходов между страницами
function Content() {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
        }
      }}
    >
      <Routes path='/' element={<Layout/>} location={displayLocation}>
        <Route path="/" element={<Web/>} />
        <Route path="/info" element={<Info/>} />
        <Route path="/airdrop" element={<Airdrop/>} />
      </Routes>
    </div>
  );
}

