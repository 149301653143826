import css from './TapToStart.module.css'
import gif from '../../img/gif.gif'
import audio from '../../img/audio.png'
import audioMuted from '../../img/audioMuted.png'
import mobx from '../../store/mobx'
import { useRef, useState } from 'react'
import gsap from 'gsap'
const TapToStart = () => {
  const ref = useRef()
    const [muted,setMuted] = useState(true)
    const click =()=>{
      gsap.to(ref.current,{opacity:0, duration:1})
      setTimeout(()=>{
        mobx.setTapToStart(true)
        
      },[500])
      setTimeout(()=>{mobx.setStarVisible(true)},[2000])
      
    }

    return (
<>
<section  className={css.container} onClick={()=>click()} style={{zIndex:9999,cursor:'pointer'}}>
  <span ref={ref} className={css.tap} >Tap to start</span>      
</section>
</>
     );
}
 
export default TapToStart;