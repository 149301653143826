import css from './Loading.module.css'
import gif from '../../img/gif.gif'
import { useState } from 'react'
const Loading = () => {
    const [muted,setMuted] = useState(true)
    return (
<>
<section className={css.container} style={{zIndex:9999}}>
        <img src={gif} className={css.gif}/>

        
        
</section>
</>
     );
}
 
export default Loading;