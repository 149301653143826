import css from './State4.module.css'
import {motion} from 'framer-motion'

const State4 = () =>{
    return(
<>
<motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:5,duration:1}} className={css.block2Container}>
<div className={css.glitchWrapper}>
                        <span data-text="Sorry, you are not eligible... Stay tuned and it will be more cook oppotunites!" className={`${css.block2Header} ${css.glitch}`}>Sorry, you are not eligible... Stay tuned and it will be more cook oppotunites!</span>
                    </div>
   
</motion.div>
</>
    )
}

export default State4