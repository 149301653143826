import css from './Header.module.css'
import triangle from '../../img/triangle.png'
import modalTrigger from '../../img/modalTrigger.png'
import mobx from '../../store/mobx'
import {observer} from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import wallet from '../../img/Wallet.png'
import topCookie from '../../img/topCookie.png'


const Header = observer(() => {
        return ( 
            <>
                <section  className={css.container}>
                <Link to="/" className={css.topCookie}><img className={css.topCookie} src={topCookie}/></Link>
                    <div className={css.navContainer}>
                        <nav className={css.nav}>
                            <Link to='/'  className={css.link}>HOME</Link>
                            <Link to='/airdrop'  className={css.link}>AIRDROP</Link>
                            <div className={css.column}>
                                <span to='#' className={`${css.link}`}>NFT</span>
                                <span className={css.soon1}>soon</span>
                            </div>   
                            <Link to='/info' className={css.link}>INFO</Link>
                            <div className={css.column}>
                                <a href='https://t.me/CookiesBand_bot/Cookies' target='blank' className={`${css.link} `}>GAME</a>
                                {/* <span className={css.soon2}>soon</span> */}
                            </div>
                            
                            
                        </nav>
                    </div>
                    <div className={css.buttonContainer}>
                        <div className={css.accountContainer}>

                        </div>
                        <button className={css.button} onClick={()=> mobx.setModalConnectWallet(true)}>Get wallet</button>
                        <img src={modalTrigger} onClick={()=>mobx.setModal(true)} className={css.modalTrigger}/>
                    </div>
                </section>
            </>
                 );

})
 
export default Header;