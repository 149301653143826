import { useState } from 'react'
import css from './State2.module.css'
import tick from '../../../img/Tick.png'
import close from '../../../img/Close.png'
import {motion} from 'framer-motion'

const State2 = () =>{
    const [state1,setState1] = useState(2)
    const [state2,setState2] = useState(1)
    const [state3,setState3] = useState(3)
    const [state4,setState4] = useState(2)
    const [state5,setState5] = useState(1)
    const [state6,setState6] = useState(3)
    return(
<>
<motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:5,duration:1}} className={css.block2Container}>
    <div className={css.glitchWrapper}>
                        <span data-text="Confirming eligibility.." className={`${css.block2Header} ${css.glitch}`}>Confirming eligibility..</span>
                    </div>
    <div className={css.nestedContainer}>
        <div className={css.column}>
            <div className={css.row}>
                 <span className={css.count}>Критерий 1</span>
                 {state1===1&&<div className={css.loading}></div>}
                 {state1===2&&<img src={tick} className={css.tick}/>}
                 {state1===3&&<img src={close} className={css.close}/>}
            </div>
            <span className={css.descr}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div>
        <div className={css.column}>
            <div className={css.row}>
                 <span className={css.count}>Критерий 2</span>
                 {state2===1&&<div className={css.loading}></div>}
                 {state2===2&&<img src={tick} className={css.tick}/>}
                 {state2===3&&<img src={close} className={css.close}/>}
            </div>
            <span className={css.descr}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div>
        <div className={css.column}>
            <div className={css.row}>
                 <span className={css.count}>Критерий 3</span>
                 {state3===1&&<div className={css.loading}></div>}
                 {state3===2&&<img src={tick} className={css.tick}/>}
                 {state3===3&&<img src={close} className={css.close}/>}
            </div>
            <span className={css.descr}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div>
        <div className={css.column}>
            <div className={css.row}>
                 <span className={css.count}>Критерий 4</span>
                 {state4===1&&<div className={css.loading}></div>}
                 {state4===2&&<img src={tick} className={css.tick}/>}
                 {state4===3&&<img src={close} className={css.close}/>}
            </div>
            <span className={css.descr}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div>
        <div className={css.column}>
            <div className={css.row}>
                 <span className={css.count}>Критерий 5</span>
                 {state5===1&&<div className={css.loading}></div>}
                 {state5===2&&<img src={tick} className={css.tick}/>}
                 {state5===3&&<img src={close} className={css.close}/>}
            </div>
            <span className={css.descr}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div>
        <div className={css.column}>
            <div className={css.row}>
                 <span className={css.count}>Критерий 6</span>
                 {state6===1&&<div className={css.loading}></div>}
                 {state6===2&&<img src={tick} className={css.tick}/>}
                 {state6===3&&<img src={close} className={css.close}/>}
            </div>
            <span className={css.descr}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</span>
        </div>
    </div>
</motion.div>
</>
    )
}

export default State2