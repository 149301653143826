import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style.css'
import './style.scss'
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';


const root = createRoot(document.getElementById('root'));
root.render(
<BrowserRouter>
    <div style={{backgroundColor:"#8669AB",height:'100%',width:'100%'}}>
    <App />
    </div>
    
</BrowserRouter>
);


