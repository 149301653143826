/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 cookiecrumbs.gltf 
*/

import React, { useLayoutEffect, useRef, useState } from 'react'
import { useGLTF, PerspectiveCamera, useAnimations } from '@react-three/drei'

export default function CookieCrumbs(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/models/cookiecrumbs.gltf')
  const { actions, names } = useAnimations(animations, group);
  const [scale,setScale] = useState(1)
  
  useLayoutEffect(() => {
    const width = window.screen.width
    // if (width<768){
    //   setScale(0.6)
    // }else if(width<1024){
    //   setScale(0.7)
    // }
    setScale(0)

    names.forEach((animation) => {
      
      setTimeout(()=>{
        actions?.[animation]?.play();
            if (width<768){
              setScale(0.6)
            }else if(width<1024){
              setScale(0.7)
            }else{setScale(1)}
      },[700])
      // Отключаем зацикленность анимации
      actions.animation_0.repetitions = true
    });
  }, [actions, names]);
  return (
    <group ref={group} {...props} dispose={null} scale={scale}>
      <group>
        <group name="Cookie" position={[-14.632, 0, -12.415]}>
          <group name="CrumbsRadius01" position={[14.632, -40, 12.415]}>
            <mesh name="Sphere_15_0_(Copy)" geometry={nodes['Sphere_15_0_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[0, 0, -232.938]} rotation={[1.809, 0.394, 1.839]} scale={0.93} />
            <mesh name="Sphere_15_1_(Copy)" geometry={nodes['Sphere_15_1_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-35.69, 0, -185.946]} rotation={[2.543, -1.429, -2.667]} scale={1.037} />
            <mesh name="Sphere_15_1_(Copy)_1" geometry={nodes['Sphere_15_1_(Copy)_1'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-35.69, 0, -185.946]} rotation={[2.543, -1.429, -2.667]} scale={1.037} />
            <mesh name="Sphere_15_2_(Copy)" geometry={nodes['Sphere_15_2_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[24.199, 0, -172.701]} rotation={[-1.613, 1.052, -0.54]} scale={0.662} />
            <mesh name="Sphere_15_3_(Copy)" geometry={nodes['Sphere_15_3_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[6.48, 0, -143.029]} rotation={[-2.322, -0.868, 1.318]} scale={0.565} />
            <mesh name="Sphere_15_4_(Copy)" geometry={nodes['Sphere_15_4_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[21.421, 0, -180.232]} rotation={[0.975, 0.615, -0.545]} scale={0.845} />
            <mesh name="Sphere_15_5_(Copy)" geometry={nodes['Sphere_15_5_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[52.74, 0, -138.746]} rotation={[-1.54, -0.178, -2.04]} scale={1.206} />
            <mesh name="Sphere_15_6_(Copy)" geometry={nodes['Sphere_15_6_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[130.349, 0, -161.288]} rotation={[0.304, 0.359, 1.295]} scale={1.35} />
            <mesh name="Sphere_15_7_(Copy)" geometry={nodes['Sphere_15_7_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[44.942, 0, -219.296]} rotation={[-0.61, 1.01, -0.948]} scale={1.427} />
            <mesh name="Sphere_15_8_(Copy)" geometry={nodes['Sphere_15_8_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[44.48, 0, -201.04]} rotation={[1.602, -0.008, 2.036]} scale={1.492} />
            <mesh name="Sphere_15_9_(Copy)" geometry={nodes['Sphere_15_9_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[121.285, 0, -104.145]} rotation={[-1.833, 0.871, 2.205]} scale={0.968} />
            <mesh name="Sphere_15_10_(Copy)" geometry={nodes['Sphere_15_10_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[183.666, 0, -125.797]} rotation={[1.709, 1.086, 0.157]} scale={0.916} />
            <mesh name="Sphere_15_11_(Copy)" geometry={nodes['Sphere_15_11_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[139.158, 0, -178.712]} rotation={[0.614, -0.296, -0.818]} scale={1.416} />
            <mesh name="Sphere_15_12_(Copy)" geometry={nodes['Sphere_15_12_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[102.634, 0, -80.353]} rotation={[-2.056, 0.205, -2.742]} scale={1.101} />
            <mesh name="Sphere_15_13_(Copy)" geometry={nodes['Sphere_15_13_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[108.831, 0, -84.87]} rotation={[1.958, 0.256, 2.74]} scale={1.135} />
            <mesh name="Sphere_15_14_(Copy)" geometry={nodes['Sphere_15_14_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[118.753, 0, -121.11]} rotation={[-0.304, -0.012, -2.115]} scale={1.366} />
            <mesh name="Sphere_15_15_(Copy)" geometry={nodes['Sphere_15_15_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[183.044, 0, -43.415]} rotation={[1.557, 0.142, -0.092]} scale={1.479} />
            <mesh name="Sphere_15_16_(Copy)" geometry={nodes['Sphere_15_16_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[174.762, 0, -28.7]} rotation={[1.66, 1.057, -0.03]} scale={0.997} />
            <mesh name="Sphere_15_17_(Copy)" geometry={nodes['Sphere_15_17_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[236.015, 0, -38.276]} rotation={[1.528, -0.263, -1.355]} scale={0.52} />
            <mesh name="Sphere_15_18_(Copy)" geometry={nodes['Sphere_15_18_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[160.948, 0, -94.149]} rotation={[-1.18, -0.094, 1.746]} scale={0.891} />
            <mesh name="Sphere_15_19_(Copy)" geometry={nodes['Sphere_15_19_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[141.076, 0, -114.907]} rotation={[2.685, -0.55, -2.404]} scale={1.084} />
            <mesh name="Sphere_15_20_(Copy)" geometry={nodes['Sphere_15_20_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[267.338, 0, -11.41]} rotation={[0.173, 1.129, 1.791]} scale={0.608} />
            <mesh name="Sphere_15_21_(Copy)" geometry={nodes['Sphere_15_21_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[242.043, 0, -56.619]} rotation={[2.531, 0.822, 0.541]} scale={0.834} />
            <mesh name="Sphere_15_22_(Copy)" geometry={nodes['Sphere_15_22_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[225.47, 0, -63.244]} rotation={[-0.333, 0.291, 1.332]} scale={0.557} />
            <mesh name="Sphere_15_23_(Copy)" geometry={nodes['Sphere_15_23_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[119.997, 0, -64.439]} rotation={[1.32, -0.366, 1.075]} scale={0.639} />
            <mesh name="Sphere_15_24_(Copy)" geometry={nodes['Sphere_15_24_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[122.367, 0, 42.065]} rotation={[1.279, 0.471, 2.178]} scale={0.793} />
            <mesh name="Sphere_15_25_(Copy)" geometry={nodes['Sphere_15_25_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[142.695, 0, 6.1]} rotation={[1.697, 0.215, -1.833]} scale={0.54} />
            <mesh name="Sphere_15_26_(Copy)" geometry={nodes['Sphere_15_26_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[259.57, 0, 11.405]} rotation={[-1.536, -0.341, -0.362]} scale={1.282} />
            <mesh name="Sphere_15_27_(Copy)" geometry={nodes['Sphere_15_27_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[125.923, 0, 31.559]} rotation={[1.885, 0.907, -2.934]} scale={0.525} />
            <mesh name="Sphere_15_28_(Copy)" geometry={nodes['Sphere_15_28_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[222.086, 0, 13.615]} rotation={[1.675, -1.325, -3.13]} scale={1.031} />
            <mesh name="Sphere_15_29_(Copy)" geometry={nodes['Sphere_15_29_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[195.792, 0, 143.099]} rotation={[1.629, 0.035, 2.619]} scale={1.1} />
            <mesh name="Sphere_15_30_(Copy)" geometry={nodes['Sphere_15_30_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[216.07, 0, 44.834]} rotation={[-1.241, 0.177, -2.512]} scale={0.864} />
            <mesh name="Sphere_15_31_(Copy)" geometry={nodes['Sphere_15_31_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[124.036, 0, 31.349]} rotation={[-1.389, -0.766, -2.506]} scale={1.392} />
            <mesh name="Sphere_15_32_(Copy)" geometry={nodes['Sphere_15_32_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[199.348, 0, 127.571]} rotation={[-0.142, 0.782, 2.014]} scale={1.486} />
            <mesh name="Sphere_15_33_(Copy)" geometry={nodes['Sphere_15_33_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[120.888, 0, 154.372]} rotation={[1.3, -0.015, 0.55]} scale={1.491} />
            <mesh name="Sphere_15_34_(Copy)" geometry={nodes['Sphere_15_34_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[164.817, 0, 95.089]} rotation={[2.281, -1.252, 1.168]} scale={1.347} />
            <mesh name="Sphere_15_35_(Copy)" geometry={nodes['Sphere_15_35_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[98.051, 0, 150.946]} rotation={[0.011, 0.252, -0.86]} scale={0.88} />
            <mesh name="Sphere_15_36_(Copy)" geometry={nodes['Sphere_15_36_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[80.63, 0, 169.489]} rotation={[-0.942, -0.809, 2.251]} scale={1.497} />
            <mesh name="Sphere_15_37_(Copy)" geometry={nodes['Sphere_15_37_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[76.486, 0, 118.026]} rotation={[1.268, 0.058, 1.195]} scale={1.421} />
            <mesh name="Sphere_15_38_(Copy)" geometry={nodes['Sphere_15_38_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[121.048, 0, 196.086]} rotation={[1.125, 0.986, -1.414]} scale={0.511} />
            <mesh name="Sphere_15_39_(Copy)" geometry={nodes['Sphere_15_39_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[85.067, 0, 200.857]} rotation={[1.632, 0.048, 2.746]} scale={1.428} />
            <mesh name="Sphere_15_40_(Copy)" geometry={nodes['Sphere_15_40_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[100.656, 0, 153.769]} rotation={[-2.384, -0.112, -3.102]} scale={0.51} />
            <mesh name="Sphere_15_41_(Copy)" geometry={nodes['Sphere_15_41_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[75.366, 0, 151.659]} rotation={[-2.257, 0.769, 1.227]} scale={1.132} />
            <mesh name="Sphere_15_42_(Copy)" geometry={nodes['Sphere_15_42_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-6.836, 0, 144.095]} rotation={[3.059, 0.219, 2.197]} scale={0.755} />
            <mesh name="Sphere_15_43_(Copy)" geometry={nodes['Sphere_15_43_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[81.995, 0, 246.595]} rotation={[1.471, -0.131, 0.478]} scale={1.302} />
            <mesh name="Sphere_15_44_(Copy)" geometry={nodes['Sphere_15_44_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[10.097, 0, 179.362]} rotation={[0.274, 1.069, 2.645]} scale={0.984} />
            <mesh name="Sphere_15_45_(Copy)" geometry={nodes['Sphere_15_45_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-30.757, 0, 150.143]} rotation={[-1.46, -0.685, -1.216]} scale={1.213} />
            <mesh name="Sphere_15_46_(Copy)" geometry={nodes['Sphere_15_46_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[62.213, 0, 118.114]} rotation={[0.107, -0.602, -0.244]} scale={1.167} />
            <mesh name="Sphere_15_47_(Copy)" geometry={nodes['Sphere_15_47_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-29.419, 0, 185.095]} rotation={[-2.618, 0.08, 1.576]} scale={0.539} />
            <mesh name="Sphere_15_48_(Copy)" geometry={nodes['Sphere_15_48_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-33.713, 0, 143.279]} rotation={[-2.575, -0.169, -0.204]} scale={1.319} />
            <mesh name="Sphere_15_49_(Copy)" geometry={nodes['Sphere_15_49_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-56.837, 0, 251.482]} rotation={[1.883, 0.498, -1.659]} scale={1.316} />
            <mesh name="Sphere_15_50_(Copy)" geometry={nodes['Sphere_15_50_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-4.244, 0, 179.253]} rotation={[-2.029, 0.448, 1.66]} scale={1.056} />
            <mesh name="Sphere_15_51_(Copy)" geometry={nodes['Sphere_15_51_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-24.046, 0, 262.446]} rotation={[-0.639, -0.397, -0.656]} scale={0.915} />
            <mesh name="Sphere_15_52_(Copy)" geometry={nodes['Sphere_15_52_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-68.11, 0, 220.982]} rotation={[-1.985, -0.632, 0.461]} scale={1.314} />
            <mesh name="Sphere_15_53_(Copy)" geometry={nodes['Sphere_15_53_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[1.555, 0, 153.866]} rotation={[1.427, 0.052, -2.804]} scale={1.185} />
            <mesh name="Sphere_15_54_(Copy)" geometry={nodes['Sphere_15_54_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-76.059, 0, 197.599]} rotation={[2.932, -0.706, 1.303]} scale={0.728} />
            <mesh name="Sphere_15_55_(Copy)" geometry={nodes['Sphere_15_55_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-16.5, 0, 136.875]} rotation={[3.064, -0.383, 1.516]} scale={0.639} />
            <mesh name="Sphere_15_56_(Copy)" geometry={nodes['Sphere_15_56_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-75.359, 0, 221.664]} rotation={[-2.231, -0.148, 1.555]} scale={1.202} />
            <mesh name="Sphere_15_57_(Copy)" geometry={nodes['Sphere_15_57_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-113.811, 0, 139.124]} rotation={[2.705, -0.073, -1.449]} scale={0.916} />
            <mesh name="Sphere_15_58_(Copy)" geometry={nodes['Sphere_15_58_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-56.828, 0, 117.156]} rotation={[1.804, 0.157, -0.003]} scale={0.784} />
            <mesh name="Sphere_15_59_(Copy)" geometry={nodes['Sphere_15_59_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-133.535, 0, 145.146]} rotation={[1.415, -0.518, 0.657]} scale={0.895} />
            <mesh name="Sphere_15_60_(Copy)" geometry={nodes['Sphere_15_60_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-111.736, 0, 151.195]} rotation={[-1.816, -0.442, -2.333]} scale={0.789} />
            <mesh name="Sphere_15_61_(Copy)" geometry={nodes['Sphere_15_61_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-73.203, 0, 116.203]} rotation={[1.95, -0.739, -2.101]} scale={0.827} />
            <mesh name="Sphere_15_62_(Copy)" geometry={nodes['Sphere_15_62_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-109.233, 0, 63.991]} rotation={[2.334, -0.081, 2.886]} scale={0.732} />
            <mesh name="Sphere_15_63_(Copy)" geometry={nodes['Sphere_15_63_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-167.981, 0, 127.382]} rotation={[1.696, -0.972, -1.733]} scale={0.907} />
            <mesh name="Sphere_15_64_(Copy)" geometry={nodes['Sphere_15_64_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-141.434, 0, 60.68]} rotation={[0.67, -0.408, -2.811]} scale={0.726} />
            <mesh name="Sphere_15_65_(Copy)" geometry={nodes['Sphere_15_65_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-174.034, 0, 85.548]} rotation={[-1.586, 0.647, 0.663]} scale={0.878} />
            <mesh name="Sphere_15_66_(Copy)" geometry={nodes['Sphere_15_66_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-250.343, 0, 79.586]} rotation={[-1.836, 0.522, 1.184]} scale={1.176} />
            <mesh name="Sphere_15_67_(Copy)" geometry={nodes['Sphere_15_67_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-233.528, 0, 10.46]} rotation={[1.567, 0.001, -1.684]} scale={0.679} />
            <mesh name="Sphere_15_68_(Copy)" geometry={nodes['Sphere_15_68_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-235.959, 0, 19.255]} rotation={[1.664, 1.106, 1.278]} scale={1.445} />
            <mesh name="Sphere_15_69_(Copy)" geometry={nodes['Sphere_15_69_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-170.44, 0, 26.838]} rotation={[-0.953, 0.301, 3.009]} scale={1.02} />
            <mesh name="Sphere_15_70_(Copy)" geometry={nodes['Sphere_15_70_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-217.348, 0, 0.021]} rotation={[0.995, 0.19, -1.418]} scale={1.389} />
            <mesh name="Sphere_15_71_(Copy)" geometry={nodes['Sphere_15_71_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-178.292, 0, 49.367]} rotation={[-1.403, -0.117, -0.154]} scale={1.059} />
            <mesh name="Sphere_15_72_(Copy)" geometry={nodes['Sphere_15_72_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-198.462, 0, -58.272]} rotation={[-0.236, -0.832, -2.414]} scale={0.8} />
            <mesh name="Sphere_15_73_(Copy)" geometry={nodes['Sphere_15_73_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-240.712, 0, -2.811]} rotation={[-2.372, 0.121, 0.385]} scale={1.394} />
            <mesh name="Sphere_15_74_(Copy)" geometry={nodes['Sphere_15_74_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-248.314, 0, -40.745]} rotation={[2.456, -0.231, 0.72]} scale={0.52} />
            <mesh name="Sphere_15_75_(Copy)" geometry={nodes['Sphere_15_75_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-139.03, 0, 21.152]} rotation={[-1.762, 0, -0.537]} scale={1.297} />
            <mesh name="Sphere_15_76_(Copy)" geometry={nodes['Sphere_15_76_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-163.722, 0, -37.708]} rotation={[-1.596, -0.107, 2.413]} scale={0.926} />
            <mesh name="Sphere_15_77_(Copy)" geometry={nodes['Sphere_15_77_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-191.882, 0, -6.155]} rotation={[0.984, 1.09, -1.477]} scale={0.78} />
            <mesh name="Sphere_15_78_(Copy)" geometry={nodes['Sphere_15_78_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-178.851, 0, -147.709]} rotation={[-2.516, -0.863, -1.721]} scale={1.246} />
            <mesh name="Sphere_15_79_(Copy)" geometry={nodes['Sphere_15_79_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-235.478, 0, -54.275]} rotation={[1.793, -0.314, 0.593]} scale={0.738} />
            <mesh name="Sphere_15_80_(Copy)" geometry={nodes['Sphere_15_80_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-107.279, 0, -111.151]} rotation={[-2.084, 0.654, 1.171]} scale={1.369} />
            <mesh name="Sphere_15_81_(Copy)" geometry={nodes['Sphere_15_81_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-136.399, 0, -79.11]} rotation={[-1.222, -0.063, -2.434]} scale={1.205} />
            <mesh name="Sphere_15_82_(Copy)" geometry={nodes['Sphere_15_82_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-142.506, 0, -72.225]} rotation={[2.793, 0.936, 1.347]} scale={1.128} />
            <mesh name="Sphere_15_83_(Copy)" geometry={nodes['Sphere_15_83_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-159.87, 0, -202.199]} rotation={[-1.688, 0.232, -1.02]} scale={0.824} />
            <mesh name="Sphere_15_84_(Copy)" geometry={nodes['Sphere_15_84_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-109.721, 0, -83.672]} rotation={[-1.061, -0.024, 1.198]} scale={0.993} />
            <mesh name="Sphere_15_85_(Copy)" geometry={nodes['Sphere_15_85_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-105.358, 0, -97.653]} rotation={[0.946, -0.17, -2.793]} scale={1.116} />
            <mesh name="Sphere_15_86_(Copy)" geometry={nodes['Sphere_15_86_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-96.928, 0, -202.209]} rotation={[1.351, -0.277, 1.971]} scale={1.467} />
            <mesh name="Sphere_15_87_(Copy)" geometry={nodes['Sphere_15_87_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-65.592, 0, -208.723]} rotation={[0.303, -0.053, -2.935]} scale={1.039} />
            <mesh name="Sphere_15_88_(Copy)" geometry={nodes['Sphere_15_88_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-49.572, 0, -191.865]} rotation={[2.674, -1.212, 2.627]} scale={0.728} />
            <mesh name="Sphere_15_89_(Copy)" geometry={nodes['Sphere_15_89_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-141.472, 0, -194.724]} rotation={[-3.109, 0.079, -2.098]} scale={1.024} />
            <mesh name="Sphere_15_90_(Copy)" geometry={nodes['Sphere_15_90_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[0.643, 0, -156.428]} rotation={[-1.098, 0.459, 1.108]} scale={0.933} />
            <mesh name="Sphere_15_91_(Copy)" geometry={nodes['Sphere_15_91_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-73.049, 0, -108.021]} rotation={[-0.271, -1.081, -1.509]} scale={0.971} />
            <mesh name="Sphere_15_92_(Copy)" geometry={nodes['Sphere_15_92_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-54.452, 0, -132.564]} rotation={[-1.636, 0.145, -3.049]} scale={0.65} />
            <mesh name="Sphere_15_93_(Copy)" geometry={nodes['Sphere_15_93_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-77.63, 0, -122.57]} rotation={[-0.809, -0.167, -2.875]} scale={1.021} />
            <mesh name="Sphere_15_94_(Copy)" geometry={nodes['Sphere_15_94_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-51.939, 0, -239.341]} rotation={[1.736, 0.046, 1.056]} scale={0.749} />
          </group>
          <group name="CrumbsRadius02" position={[14.632, -40, 12.415]}>
            <mesh name="Sphere_16_0_(Copy)" geometry={nodes['Sphere_16_0_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[0, 0, -248.688]} rotation={[1.941, 0.653, 1.658]} scale={1.07} />
            <mesh name="Sphere_16_1_(Copy)" geometry={nodes['Sphere_16_1_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[67.36, 0, -194.372]} rotation={[0.045, -0.76, -2.751]} scale={0.963} />
            <mesh name="Sphere_16_2_(Copy)" geometry={nodes['Sphere_16_2_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[51.208, 0, -186.534]} rotation={[2.331, -0.277, 1.498]} scale={1.338} />
            <mesh name="Sphere_16_3_(Copy)" geometry={nodes['Sphere_16_3_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[81.54, 0, -141.643]} rotation={[1.984, 0.718, 2.996]} scale={1.435} />
            <mesh name="Sphere_16_4_(Copy)" geometry={nodes['Sphere_16_4_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[122.041, 0, -157.896]} rotation={[2.771, -0.592, -0.339]} scale={1.155} />
            <mesh name="Sphere_16_5_(Copy)" geometry={nodes['Sphere_16_5_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[103.42, 0, -133.556]} rotation={[1.783, 0.389, 0.711]} scale={0.794} />
            <mesh name="Sphere_16_6_(Copy)" geometry={nodes['Sphere_16_6_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[125.227, 0, -183.528]} rotation={[0.135, -0.519, -2.536]} scale={0.65} />
            <mesh name="Sphere_16_7_(Copy)" geometry={nodes['Sphere_16_7_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[218.314, 0, -94.706]} rotation={[-0.136, -0.353, 2.515]} scale={0.573} />
            <mesh name="Sphere_16_8_(Copy)" geometry={nodes['Sphere_16_8_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[214.252, 0, -51.297]} rotation={[0.675, 0.33, -0.715]} scale={0.508} />
            <mesh name="Sphere_16_9_(Copy)" geometry={nodes['Sphere_16_9_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[152.521, 0, -91.7]} rotation={[0.878, 0.271, 0.057]} scale={1.032} />
            <mesh name="Sphere_16_10_(Copy)" geometry={nodes['Sphere_16_10_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[212.643, 0, -102.287]} rotation={[-0.771, 0.177, 0.963]} scale={1.084} />
            <mesh name="Sphere_16_11_(Copy)" geometry={nodes['Sphere_16_11_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[242.441, 0, -4.011]} rotation={[-1.532, -0.006, -2.372]} scale={0.584} />
            <mesh name="Sphere_16_12_(Copy)" geometry={nodes['Sphere_16_12_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[151.584, 0, -2.004]} rotation={[1.034, 0.235, 0.249]} scale={0.899} />
            <mesh name="Sphere_16_13_(Copy)" geometry={nodes['Sphere_16_13_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[156.779, 0, 26.815]} rotation={[-1.552, 0.176, 2.417]} scale={0.865} />
            <mesh name="Sphere_16_14_(Copy)" geometry={nodes['Sphere_16_14_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[169.171, 0, 82.791]} rotation={[1.583, 0.887, -2.502]} scale={0.634} />
            <mesh name="Sphere_16_15_(Copy)" geometry={nodes['Sphere_16_15_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[198.585, 0, 42.526]} rotation={[-1.172, -0.152, -2.747]} scale={0.521} />
            <mesh name="Sphere_16_16_(Copy)" geometry={nodes['Sphere_16_16_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[181.279, 0, 65.511]} rotation={[-1.967, 0.717, -3.058]} scale={1.003} />
            <mesh name="Sphere_16_17_(Copy)" geometry={nodes['Sphere_16_17_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[217.973, 0, 126.079]} rotation={[2.478, -0.593, 1.254]} scale={1.48} />
            <mesh name="Sphere_16_18_(Copy)" geometry={nodes['Sphere_16_18_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[109.988, 0, 172.06]} rotation={[1.285, 0.454, -3.072]} scale={1.109} />
            <mesh name="Sphere_16_19_(Copy)" geometry={nodes['Sphere_16_19_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[51.02, 0, 190.169]} rotation={[0.201, -0.662, 0.653]} scale={0.916} />
            <mesh name="Sphere_16_20_(Copy)" geometry={nodes['Sphere_16_20_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[152.058, 0, 234.944]} rotation={[3.08, 1.062, 0.882]} scale={1.392} />
            <mesh name="Sphere_16_21_(Copy)" geometry={nodes['Sphere_16_21_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[61.369, 0, 256.117]} rotation={[-1.674, -0.05, 0.698]} scale={1.166} />
            <mesh name="Sphere_16_22_(Copy)" geometry={nodes['Sphere_16_22_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[3.018, 0, 249.018]} rotation={[-1.327, 1.436, 1.983]} scale={1.443} />
            <mesh name="Sphere_16_23_(Copy)" geometry={nodes['Sphere_16_23_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[0, 0, 144.985]} rotation={[-1.032, 0.105, -2.901]} scale={1.361} />
            <mesh name="Sphere_16_24_(Copy)" geometry={nodes['Sphere_16_24_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[13.598, 0, 148.219]} rotation={[-1.548, -0.591, -0.054]} scale={1.207} />
            <mesh name="Sphere_16_25_(Copy)" geometry={nodes['Sphere_16_25_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-48.741, 0, 156.186]} rotation={[2.393, 0.029, 1.639]} scale={1.46} />
            <mesh name="Sphere_16_26_(Copy)" geometry={nodes['Sphere_16_26_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-129.623, 0, 241.02]} rotation={[1.085, -0.151, 0.657]} scale={0.718} />
            <mesh name="Sphere_16_27_(Copy)" geometry={nodes['Sphere_16_27_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-75.77, 0, 131.026]} rotation={[2.077, 0.187, -1.66]} scale={1.475} />
            <mesh name="Sphere_16_28_(Copy)" geometry={nodes['Sphere_16_28_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-179.181, 0, 155.608]} rotation={[0.102, 0.138, 0.773]} scale={0.969} />
            <mesh name="Sphere_16_29_(Copy)" geometry={nodes['Sphere_16_29_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-142.681, 0, 211.388]} rotation={[-1.324, 0.311, 0.104]} scale={0.9} />
            <mesh name="Sphere_16_30_(Copy)" geometry={nodes['Sphere_16_30_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-212.191, 0, 102.723]} rotation={[-1.901, -0.711, 0.958]} scale={1.136} />
            <mesh name="Sphere_16_31_(Copy)" geometry={nodes['Sphere_16_31_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-141.332, 0, 45.39]} rotation={[-2.141, -0.208, -1.791]} scale={0.608} />
            <mesh name="Sphere_16_32_(Copy)" geometry={nodes['Sphere_16_32_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-236.018, 0, 88.868]} rotation={[1.681, -0.211, 1.401]} scale={0.514} />
            <mesh name="Sphere_16_33_(Copy)" geometry={nodes['Sphere_16_33_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-191.88, 0, 88.761]} rotation={[2.536, 0.464, 0.355]} scale={0.509} />
            <mesh name="Sphere_16_34_(Copy)" geometry={nodes['Sphere_16_34_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-207.491, 0, -10.5]} rotation={[1.947, 0.042, -1.967]} scale={0.653} />
            <mesh name="Sphere_16_35_(Copy)" geometry={nodes['Sphere_16_35_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-195.531, 0, 24.242]} rotation={[3.136, 0.651, -0.665]} scale={1.12} />
            <mesh name="Sphere_16_36_(Copy)" geometry={nodes['Sphere_16_36_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-203.057, 0, 7.966]} rotation={[-2.093, 0.029, 0.103]} scale={0.503} />
            <mesh name="Sphere_16_37_(Copy)" geometry={nodes['Sphere_16_37_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-156.089, 0, -40.522]} rotation={[2.344, -0.222, -0.951]} scale={0.579} />
            <mesh name="Sphere_16_38_(Copy)" geometry={nodes['Sphere_16_38_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-224.867, 0, -100.113]} rotation={[-0.863, -0.406, -0.415]} scale={1.489} />
            <mesh name="Sphere_16_39_(Copy)" geometry={nodes['Sphere_16_39_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-206.572, 0, -109.712]} rotation={[-1.928, -0.181, -3.047]} scale={0.572} />
            <mesh name="Sphere_16_40_(Copy)" geometry={nodes['Sphere_16_40_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-138.608, 0, -147.093]} rotation={[1.596, 0.015, 2.162]} scale={1.49} />
            <mesh name="Sphere_16_41_(Copy)" geometry={nodes['Sphere_16_41_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-115.572, 0, -149.116]} rotation={[-1.303, -0.453, 2.95]} scale={0.868} />
            <mesh name="Sphere_16_42_(Copy)" geometry={nodes['Sphere_16_42_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-121.248, 0, -106.106]} rotation={[1.558, 0.312, -0.366]} scale={1.245} />
            <mesh name="Sphere_16_43_(Copy)" geometry={nodes['Sphere_16_43_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-104.491, 0, -253.383]} rotation={[-1.869, 0.017, -1.965]} scale={0.698} />
            <mesh name="Sphere_16_44_(Copy)" geometry={nodes['Sphere_16_44_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-78.179, 0, -181.437]} rotation={[-1.627, -0.219, -1.959]} scale={1.016} />
            <mesh name="Sphere_16_45_(Copy)" geometry={nodes['Sphere_16_45_(Copy)'].geometry} material={materials.Hazelnut_Cookie_01_Material} position={[-68.153, 0, -156.021]} rotation={[2.087, -0.416, 2.831]} scale={0.787} />
          </group>
        </group>
        <PerspectiveCamera name="Cam" makeDefault={false} far={10000000000} near={0.01} fov={4.718} position={[0, 8592.837, 0]} rotation={[-Math.PI / 2, 0, 0]} />
      </group>
    </group>
  )
}

useGLTF.preload('/models/cookiecrumbs.gltf')
