import css from './State1.module.css'
import mobx from '../../../store/mobx'
import {motion} from 'framer-motion'

const State1 = () =>{
    return(
<>
<motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{delay:5,duration:1}} className={css.block2Container}>
    <h2 className={css.block2Header}>Connect wallet</h2>
    <p className={css.block2Description}>We require your connection to confirm eligibility</p>
    <a className={css.button} onClick={()=> mobx.setModalConnectWallet(true)} > Connect wallet</a>
    <div className={css.nestedContainer}>
        <div className={css.column}>
            <span className={css.count}>20</span>
            <span className={css.descr}>Days</span>
        </div>
        <div className={css.column}>
            <span className={css.count}>08</span>
            <span className={css.descr}>hours</span>
        </div>
        <div className={css.column}>
            <span className={css.count}>42</span>
            <span className={css.descr}>minutes</span>
        </div>
        <div className={css.column}>
            <span className={css.count}>13</span>
            <span className={css.descr}>seconds</span>
        </div>
    </div>
</motion.div>
</>
    )
}

export default State1