import {makeAutoObservable} from 'mobx'

class Mobx {
    constructor(){
        makeAutoObservable(this)
    }
    modal = false
    modalByToken = false
    modalConnectWallet = false
    disclamerModal = false
    count = 0
    group1 = false
    group2 = false
    group3 = false
    tapToStart = false
    starVisible = false
    binanceModalVisible = false
    binanceID = 1
    dollarVisibe = false

    setDollarVisible(bool){
        this.dollarVisibe = bool
    }

    setBinanceID(id){
        this.binanceID=id
    }
    binanceArrow =[
        {
            "icon":'raydium.svg',
            "link":'https://raydium.io/swap/?inputMint=sol&outputMint=4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
            'name':'Raydium'
        },
        {
            "icon":'Jupiter.svg',
            "link":'https://jup.ag/',
            'name':'Jupiter'
        },
    ]
    connectArrow =[
        {
            "icon":'solflare.svg',
            "link":'https://solflare.com/',
            'name':'Solflare'
        },
        {
            "icon":'trust.svg',
            "link":'https://trustwallet.com/ru',
            'name':'Trust '
        },
    ]
    setBinanceModalVisible(bool){
        this.binanceModalVisible = bool
    }
    setStarVisible(bool){
        this.starVisible = bool
    }
    setTapToStart(bool){
    this.tapToStart = bool
    }
    setModal(bool){
    this.modal = bool
    }
    setModalByToken(bool){
    this.modalByToken = bool
    }
    setModalConnectWallet(bool){
    this.modalConnectWallet = bool
    }
    setDisclamerModal(bool){
    this.disclamerModal = bool
    }
    setCount(newCount){
        this.count = newCount
    }
    setGrop1(){
        this.group1=true
    }
    setGrop2(){
        this.group2=true
    }
    setGrop3(){
        this.group3=true
    }
}

export default new Mobx();