import css from './Modal.module.css'
import close from '../../img/Closeу3.png'
import mobx from '../../store/mobx'
import {observer} from 'mobx-react-lite'
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom'

const Modal = observer(() => {
    if(mobx.modal){
        return ( 
            <>
                    <motion.section  initial={{y:-200}} whileInView={{y:0}}  className={css.container}>
                    
                    <img  src={close} onClick={()=>mobx.setModal(false)} className={css.close}/>
                    <nav className={css.nav}>
                        <Link to="/" onClick={()=> mobx.setModal(false)} className={css.link}>HOME</Link>
                        <Link to='/airdrop' onClick={()=> mobx.setModal(false)} className={css.link}>AIRDROP</Link>
                        <div className={css.column}>
                        <span to='/nft'  className={css.link}>NFT</span>
                            <span className={css.span}>soon</span>
                        </div>
                        
                        <Link to='/info' onClick={()=>mobx.setModal(false)} className={css.link}>INFO</Link>
                        <div className={css.column}>
                        <a href='https://t.me/CookiesBand_bot/Cookies' target='blank' className={`${css.link} `}>GAME</a>
                            {/* <span className={css.span}>soon</span> */}
                        </div>
                            
                            
                            
                    </nav>
                    <button className={css.button} onClick={()=>{mobx.setModalConnectWallet(true); mobx.setModal(false)}}>Get a wallet</button>
                </motion.section>
            
            </>
                 );
    }
   
})
 
export default Modal;