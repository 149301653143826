import Footer from '../../components/Footer/Footer';
import css from './Info.module.css'
import topCookie from '../../img/topCookie.png'
import cookie7 from '../../img/cookie7.png'
import cookie8 from '../../img/cookie8.png'
import cookie9 from '../../img/cookie9.png'
import cookie10 from '../../img/cookie10.png'
import cookie11 from '../../img/cookie11.png'
import cookie13 from '../../img/cookie13.png'
import cookie14 from '../../img/cookie14.png'
import cookie15 from '../../img/cookie15.png'
import { useLayoutEffect, useState } from 'react';
import State1 from '../../components/Airdrop/State1/State1';
import State2 from '../../components/Airdrop/State2/State2';
import State3 from '../../components/Airdrop/State3/State3';
import State4 from '../../components/Airdrop/State4/State4';
import State5 from '../../components/Airdrop/State5/State5';
import Header from '../../components/Header/Header';
import {motion} from 'framer-motion'
import { Link } from 'react-router-dom';
import jQuery from 'jquery'

const Info = () => {
    const [state5, setState5] = useState(true)
    const [headerOpacity,setHeaderOpacity]= useState(0)

    useLayoutEffect(()=>{
        setTimeout(()=>{
            // Появление хедера
            setHeaderOpacity(1);
            (function($) {
                var s,
                spanizeLetters = {
                  settings: {
                    letters: $('.js-spanize'),
                  },
                  init: function() {
                    s = this.settings;
                    this.bindEvents();
                  },
                  bindEvents: function(){
                    s.letters.html(function (i, el) {
                      //spanizeLetters.joinChars();
                      var spanizer = $.trim(el).split("");
                      return '<span>' + spanizer.join('</span><span>') + '</span>';
                    });
                  },
                };
                spanizeLetters.init();
              })(jQuery);
        },[1000])
    },[])
    return ( 
<>
    <section className={css.container}>

    <motion.img viewport={{once:true}} initial={{x:-100,opacity:0}} whileInView={{x:0,opacity:1}} transition={{duration:.7,delay:.5}} src={cookie9} className={css.cookie9}/> 
        <motion.img viewport={{once:true}} initial={{x:50,y:-50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie10} className={css.cookie10}/> 
        {window.screen.width>768?
        <motion.img viewport={{once:true}} initial={{x:-150,y:100,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie11} className={css.cookie11}/> :
        <motion.img viewport={{once:true}} initial={{x:-50,y:50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}   src={cookie11} className={css.cookie11}/>        
    }

        <motion.img viewport={{once:true}} initial={{x:100,y:-50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie13} className={css.cookie13}/> 
        {window.screen.width>768?
        <motion.img viewport={{once:true}} initial={{x:-50,y:-50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie14} className={css.cookie14}/> :
        <motion.img viewport={{once:true}} initial={{x:50,y:50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie14} className={css.cookie14}/>}
        <motion.img viewport={{once:true}} initial={{x:100,y:50,opacity:0}} whileInView={{x:0,y:0,opacity:1}}  transition={{duration:.7,delay:.5}}  src={cookie15} className={css.cookie15}/> 
        {/* Hero */}
        <div className={css.hero}>
        <section  className="mast" style={{opacity:headerOpacity}}>
                <header className="mast__header">
                    <h1 className={`mast__title js-spanize ${css.headerInfo}`} id='header-info'>OUR JORNEY</h1> 
                    <motion.p initial={{opacity:0,y:-100}} whileInView={{opacity:1,y:0}} transition={{delay:2}} viewport={{once:true}}  className={css.header2}  id='description-info'>
                    Discover how Cookies Token is revolutionizing the crypto space with its unique utility and 
                    rewarding opportunities. Join us on this exciting journey of growth and prosperity!</motion.p>
                </header>
            </section>
            
        </div>  {/* Hero */}
        {/* Block2 */}
         <State5/>


         {window.screen.width>768?
        <motion.img viewport={{once:true}} initial={{opacity:0,x:-150,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie7} className={css.cookie7}/>:
        <motion.img viewport={{once:true}} initial={{opacity:0,x:-50,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie7} className={css.cookie7}/>}
        {window.screen.width>768?
        <motion.img viewport={{once:true}} initial={{opacity:0,x:150,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie8} className={css.cookie8}/>:
        <motion.img viewport={{once:true}} initial={{opacity:0,x:50,y:50}} whileInView={{opacity:1,x:0,y:0}} transition={{duration:1}} src={cookie8} className={css.cookie8}/>}
        <Footer/>  
    </section>
</>
     );
}
 
export default Info;